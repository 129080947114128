<template>
  <div
    class="content-component column"
    :class="[{ 'component-active': isActive }, component.question.type]"
    :style="objectStyles"
    @click="updateCurrentComponent"
  >
    <span class="count" style="margin-left: -14px">{{ componentCount }}</span>
    <div class="mt-5">
      <label class="ml-3 pl-1">{{ $t("instruction") }}:</label>
      <div style="margin-right: -12px; padding-left: 5px">
        <ckeditor
          :editor="editor"
          :placeholder="$t('questionPlaceHolder')"
          v-model="instruction"
          :config="editorConfig"
        ></ckeditor>
      </div>
    </div>
    <div class="mt-3">
      <label class="ml-3 pl-1">{{ $t("primaryText") }}:</label>
      <div style="margin-right: -12px; padding-left: 5px">
        <ckeditor
          :editor="editor"
          :placeholder="$t('questionPlaceHolder')"
          v-model="primaryTextSource"
          :config="editorConfig"
        ></ckeditor>
      </div>
    </div>
    <div class="mt-3">
      <label class="ml-3 pl-1" for="question">{{ $t("questionTag") }}:</label>
      <div style="margin-right: -12px; padding-left: 5px">
        <ckeditor
          :editor="editor"
          :placeholder="$t('questionPlaceHolder')"
          v-model="question"
          :config="editorConfig"
        ></ckeditor>
      </div>
    </div>
    <span class="legend centered">{{ $t("writtenLegend") }}</span>
    <button class="btn secondary delete" @click="deleteComponent">
      {{ $t("delete") }}
    </button>
  </div>
</template>

<script>
import BackOfficeComponentMixin from "@/components/BackOffice/Mixins/BackOfficeComponentMixin.js";

export default {
  name: "WrittenAnswerComponent",
  mixins: [BackOfficeComponentMixin],
  props: ["componentCount"],
  computed: {
    question: {
      get() {
        return this.component.question.question[this.locale];
      },
      set(value) {
        this.setBuilderComponentPropertyValue(value, "question", this.locale);
      },
    },
    objectStyles() {
      return {
        order: this.component.order,
      };
    },
    primaryTextSource: {
      get() {
        return this.component.question.primaryTextSource[this.locale];
      },
      set(value) {
        this.setBuilderComponentPropertyValue(
          value,
          "primaryTextSource",
          this.locale
        );
      },
    },
    instruction: {
      get() {
        return this.component.question.instruction[this.locale];
      },
      set(value) {
        this.setBuilderComponentPropertyValue(
          value,
          "instruction",
          this.locale
        );
      },
    },
  },
  methods: {
    setBuilderComponentPropertyValue(value, propertyName, language) {
      this.$store.commit("setBuilderComponentPropertyValue", {
        component: this.component.question,
        propertyName: propertyName,
        value: value,
        language: language,
      });
    },
  },
};
</script>
<style>
/* .writen-ans__comppnent .ck.ck-editor{
   margin-top: 4px !important;
 } */
</style>