var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "choice row ml-3" },
      [
        _c("label", { attrs: { for: "text" } }, [
          _vm._v(_vm._s(_vm.$t("choiceTag")) + " " + _vm._s(_vm.index))
        ]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.text,
              expression: "text"
            }
          ],
          attrs: { type: "text", placeholder: _vm.$t("placeHolderChoice") },
          domProps: { value: _vm.text },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.text = $event.target.value
            }
          }
        }),
        _c("check-box", {
          attrs: {
            component: _vm.component,
            options: { text: _vm.$t("correctChoiceTag"), model: "correct" }
          }
        }),
        _c("img", {
          attrs: { src: _vm.deleteIcon },
          on: { click: _vm.removeChoice }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }