var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "element row" }, [
    _c("label", { attrs: { for: "text" } }, [
      _vm._v(_vm._s(_vm.$t("elementTag")) + " " + _vm._s(_vm.index))
    ]),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.text,
          expression: "text"
        }
      ],
      attrs: { type: "text", placeholder: _vm.$t("elementTag") + "..." },
      domProps: { value: _vm.text },
      on: {
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.text = $event.target.value
        }
      }
    }),
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.correctAnswerSet,
            expression: "correctAnswerSet"
          }
        ],
        key: _vm.primarySetName + _vm.secondarySetName,
        on: {
          change: function($event) {
            var $$selectedVal = Array.prototype.filter
              .call($event.target.options, function(o) {
                return o.selected
              })
              .map(function(o) {
                var val = "_value" in o ? o._value : o.value
                return val
              })
            _vm.correctAnswerSet = $event.target.multiple
              ? $$selectedVal
              : $$selectedVal[0]
          }
        }
      },
      _vm._l(_vm.setOptions, function(setOption) {
        return _c(
          "option",
          { key: setOption.name, domProps: { value: setOption.value } },
          [_vm._v(_vm._s(setOption.name))]
        )
      })
    ),
    _c("img", {
      attrs: { src: _vm.deleteIcon },
      on: { click: _vm.removeElement }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }