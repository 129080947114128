<template>
    <div class="element row" >
        <label for="text">{{$t('elementTag')}} {{index}}</label>
        <input type="text" v-model="text" :placeholder="$t('elementTag')+'...'">
        <select v-model="correctAnswerSet" :key="primarySetName + secondarySetName">
            <!-- TODO: turn option's keys uniques -->
            <option v-for="setOption in setOptions" :key="setOption.name"
                    :value="setOption.value">{{setOption.name}}</option>
        </select>
        <img :src="deleteIcon" @click="removeElement"/>
    </div>
</template>
<script>
import BackOfficeComponentMixin from '@/components/BackOffice/Mixins/BackOfficeComponentMixin.js'
import CheckBox from '@/components/BackOffice/ComponentSetters/CheckBox.vue'
export default {
    name: 'VennDiagramElement', 
    mixins:[BackOfficeComponentMixin], 
    components:{
        CheckBox
    },
    props:{
        index: Number, 
        question: Object
    },
    computed:{
        text:{
            get(){
                return this.component.content[this.locale]
            }, 
            set(value)
            {
                this.setComponentPropertyValue(value, 'content', this.locale)
            }
        },
        correctAnswerSet:{
            get(){
                return this.component.correctAnswerSet
            }, 
            set(value)
            {
                this.setComponentPropertyValue(value, 'correctAnswerSet')
            }
        },
        deleteIcon(){
            return require('@/assets/icons/ic_delete.svg')
        },
        primarySetName(){
            return this.question.question.primarySetContent[this.locale] || 'Primary'
        },
        secondarySetName(){
            return this.question.question.secondarySetContent[this.locale] || 'secondary'
        },
        bothSets(){
            return this.$t('both')
        },
        setOptions(){
           return[
                {
                    name: this.primarySetName, 
                    value: 'primary-set'
                },
                {
                    name: this.secondarySetName, 
                    value: 'secondary-set'
                },
                {
                    name: this.bothSets, 
                    value: 'intersection'
                },
            ]
        }
    },
    methods:{
        removeElement(){
            this.$store.dispatch('removeElementFromVennDiagram',{
                question: this.question.question, 
                element: this.component
            })
        }, 
        updateElement(){

        }
    }
}
</script>
