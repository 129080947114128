<template>
  <div
    class="content-component column"
    :class="[{ 'component-active': isActive }, component.question.type]"
    :style="objectStyles"
    @click="updateCurrentComponent"
  >
    <span class="count" style="margin-left:-14px">{{ componentCount }}</span>
    <div class="mt-3">
      <label class="ml-3" style="width:100%">{{ $t("instruction") }}:</label>
      <div class="mcq__component ml-3">
      <ckeditor
        :editor="editor"
        :placeholder="$t('questionPlaceHolder')"
        v-model="instruction"
        :config="editorConfig"
      ></ckeditor>
      </div>
    </div>
    <div class="mt-3">
      <label class="ml-3" style="width:100%">{{ $t("primaryText") }}:</label>
      <div class="mcq__component ml-3">
      <ckeditor
        :editor="editor"
        :placeholder="$t('questionPlaceHolder')"
        v-model="primaryTextSource"
        :config="editorConfig"
      ></ckeditor>
      </div>
    </div>
    <div class="mt-3">
      <label class="ml-3">{{ $t("questionTag") }}:</label>
      <div class="mcq__component ml-3">
      <ckeditor
        :editor="editor"
        :placeholder="$t('questionPlaceHolder')"
        v-model="question"
        :config="editorConfig"
      ></ckeditor>
      </div>
    </div>
    <question-choice
      v-for="(choice, index) in choices"
      :key="index"
      :index="index + 1"
      :question="component.question"
      :component="choice"
      v-on:correctChange="updateCorrectChoice"
    />
    <span class="legend centered">{{ $t("multipleChoiceLegend") }}</span>
    <button class="btn secondary delete" @click="deleteComponent">
      {{ $t("delete") }}
    </button>
    <button class="btn secondary new-choice ml-2" @click="addChoice" v-if="choices.length <= 3">
      {{ $t("addChoiceTag") }}
    </button>
  </div>
</template>
<script>
import BackOfficeComponentMixin from "@/components/BackOffice/Mixins/BackOfficeComponentMixin.js";
import QuestionChoice from "./QuestionChoice.vue";
export default {
  name: "MultipleChoiceComponent",
  mixins: [BackOfficeComponentMixin],
  props: ["componentCount"],
  components: {
    QuestionChoice,
  },
  computed: {
    question: {
      get() {
        return this.component.question.question[this.locale];
      },
      set(value) {
        this.setBuilderComponentPropertyValue(value, "question", this.locale);
      },
    },
    primaryTextSource:{
      get() {
        return this.component.question.primaryTextSource[this.locale];
      },
      set(value) {
        this.setBuilderComponentPropertyValue(value, "primaryTextSource", this.locale);
      },
    },
    instruction:{
       get() {
        return this.component.question.instruction[this.locale];
      },
      set(value) {
        this.setBuilderComponentPropertyValue(value, "instruction", this.locale);
      },
    },
    choices() {
      return this.component.question.choices;
    },
    objectStyles() {
      return {
        order: this.component.order,
      };
    },
  },
  methods: {
    addChoice() {
      this.$store.dispatch("addChoiceToQuestion", this.component.question);
    },
    updateCorrectChoice(choice) {
      this.$store.dispatch("updateCorrectAnswer", {
        question: this.component.question,
        choice: choice,
      });
    },
    setBuilderComponentPropertyValue(value, propertyName, language) {
      this.$store.commit("setBuilderComponentPropertyValue", {
        component: this.component.question,
        propertyName: propertyName,
        value: value,
        language: language
      });
    },
  },
};
</script>
<style lang="less" scoped>
.content-component.multiple-choice .row{
  height: auto;
}
.mcq__component {
    margin-top: 0px !important;
    // width: 95% !important;
}
.count{
  color: #000;
}
</style>



