<template>
<div>
    <div class="choice row ml-3" >
        <label for="text">{{$t('choiceTag')}} {{index}}</label>
        <input type="text" v-model="text" :placeholder="$t('placeHolderChoice')">
        <check-box :component="component" :options="{text:$t('correctChoiceTag'), model:'correct'}"/>
        <img :src="deleteIcon" @click="removeChoice"/>
    </div>
    <!-- <div class="row">
        <label>{{$t('response')}}</label>
        <input type="text" v-model="responseText" :placeholder="$t('placeHolderChoice')">
    </div> -->
    </div>
</template>
<script>
import BackOfficeComponentMixin from '@/components/BackOffice/Mixins/BackOfficeComponentMixin.js'
import CheckBox from '@/components/BackOffice/ComponentSetters/CheckBox.vue'
export default {
    name: 'QuestionChoice', 
    mixins:[BackOfficeComponentMixin], 
    components:{
        CheckBox
    },
    props:{
        index: Number, 
        question: Object
    },
    computed:{
        text:{
            get(){
                return this.component.text[this.locale]
            }, 
            set(value)
            {
                this.setComponentPropertyValue(value, 'text', this.locale)
            }
        }, 
        // responseText:{
        //      get(){
        //         return this.component.responseText[this.locale]
        //     }, 
        //     set(value)
        //     {
        //         this.setComponentPropertyValue(value, 'responseText', this.locale)
        //     }
        // },
        deleteIcon(){
            return require('@/assets/icons/ic_delete.svg')
        },
    },
    methods:{
        removeChoice(){
            this.$store.dispatch('removeChoiceFromQuestion',{
                question: this.question, 
                choice: this.component
            })
        }
    },
    watch:{
        'component.correct': function(){
            let questionHasCorrectChoice  = this.question.choices.find(e => e.correct)
            if(!questionHasCorrectChoice){
                this.setComponentPropertyValue(true, 'correct')
                return
            }
            this.$emit('correctChange', this.component)
        }
    } 
}
</script>
