var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "venn-diagram-set row ml-4" },
    [
      _c("image-upload", {
        key: _vm.SetImage.id,
        attrs: { component: _vm.SetImage, options: _vm.SetImageOptions }
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.SetName,
            expression: "SetName"
          }
        ],
        attrs: { type: "text", placeholder: _vm.$t("titlePlaceHolder") },
        domProps: { value: _vm.SetName },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.SetName = $event.target.value
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }