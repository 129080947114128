<template>
  <div
    class="content-component column"
    :class="[{ 'component-active': isActive }, component.question.type]"
    :style="objectStyles"
    @click="updateCurrentComponent"
  >
    <span class="count" style="margin-left: -14px">{{ componentCount }}</span>
    <div class="mt-5">
      <label class="ml-3">{{ $t("instruction") }}:</label>
      <div class="ml-3">
        <ckeditor
          :editor="editor"
          :placeholder="$t('questionPlaceHolder')"
          v-model="instruction"
          :config="editorConfig"
        ></ckeditor>
      </div>
    </div>
    <div class="mt-3">
      <label class="ml-3">{{ $t("primaryText") }}:</label>
      <div class="ml-3">
        <ckeditor
          :editor="editor"
          :placeholder="$t('questionPlaceHolder')"
          v-model="primaryTextSource"
          :config="editorConfig"
        ></ckeditor>
      </div>
    </div>
    <div class="mt-3">
      <label class="ml-3">{{ $t("questionTag") }}:</label>
      <div class="ml-3">
        <ckeditor
          :editor="editor"
          :placeholder="$t('questionPlaceHolder')"
          v-model="question"
          :config="editorConfig"
        ></ckeditor>
      </div>
    </div>
    <div class="column venn-diagram-sets">
      <venn-diagram-set :set="'primarySet'" :component="component" />
      <venn-diagram-set :set="'secondarySet'" :component="component" />
    </div>
    <venn-diagram-element
      v-for="(element, index) in elements"
      :key="'venn-diagram-element' + element.id"
      :index="index + 1"
      :question="component"
      :component="element"
    />
    <span class="legend centered">{{ $t("vennDiagramLegend") }}</span>
    <button class="btn secondary delete" @click="deleteComponent">
      {{ $t("delete") }}
    </button>
    <button class="btn secondary new-element ml-3" @click="addElement">
      {{ $t("addElementTag") }}
    </button>
  </div>
</template>
<script>
import BackOfficeComponentMixin from "@/components/BackOffice/Mixins/BackOfficeComponentMixin.js";
import VennDiagramElement from "./VennDiagramElement.vue";
import VennDiagramSet from "./VennDiagramSet.vue";
export default {
  name: "VennDiagramComponent",
  mixins: [BackOfficeComponentMixin],
  props: ["componentCount"],
  components: {
    VennDiagramElement,
    VennDiagramSet,
  },
  computed: {
    question: {
      get() {
        return this.component.question.question[this.locale];
      },
      set(value) {
        this.setBuilderComponentPropertyValue(value, "question", this.locale);
      },
    },
    elements() {
      return this.component.question.vennDiagramElements;
    },
    objectStyles() {
      return {
        order: this.component.order,
      };
    },
    primaryTextSource: {
      get() {
        return this.component.question.primaryTextSource[this.locale];
      },
      set(value) {
        this.setBuilderComponentPropertyValue(
          value,
          "primaryTextSource",
          this.locale
        );
      },
    },
    instruction: {
      get() {
        return this.component.question.instruction[this.locale];
      },
      set(value) {
        this.setBuilderComponentPropertyValue(
          value,
          "instruction",
          this.locale
        );
      },
    },
  },
  methods: {
    setBuilderComponentPropertyValue(value, propertyName, language) {
      this.$store.commit("setBuilderComponentPropertyValue", {
        component: this.component.question,
        propertyName: propertyName,
        value: value,
        language: language,
      });
    },
    addElement() {
      this.$store.dispatch("addVennDiagramElement", this.component.question);
    },
  },
};
</script>
<style lang="less" scoped>
.row {
  height: auto !important;
}
// .venndiagram__comppnent .ck.ck-editor{
//   //  width: min-content !important;
//  }
.primarytext-label {
  width: 100% !important;
  max-width: unset !important;
}
</style>



