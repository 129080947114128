<template>
  <div
    class="content-component essay-component column"
    :class="[component.question.type, { 'component-active': isActive }]"
    :style="objectStyles"
    @click="updateCurrentComponent"
  >
    <span class="count" style="margin-left: -14px">{{ componentCount }}</span>
    <!-- <div class="mt-3">
      <label class="ml-3" style="width:100%">{{ $t("instruction") }}:</label>
      <div class="mcq__component ml-3">
      <ckeditor
        :editor="editor"
        :placeholder="$t('questionPlaceHolder')"
        v-model="instruction"
        :config="editorConfig"
      ></ckeditor>
      </div>
    </div> -->
    <div class="mt-3">
      <label class="ml-3 pl-1">{{ $t("instruction") }}:</label>
      <div style="margin-right: -12px; padding-left: 5px">
        <ckeditor
          :editor="editor"
          :placeholder="$t('questionPlaceHolder')"
          v-model="instruction"
          :config="editorConfig"
        ></ckeditor>
      </div>
    </div>
    <div class="mt-3">
      <label class="ml-3 pl-1">{{ $t("primaryText") }}:</label>
      <div style="margin-right: -12px; padding-left: 5px">
        <ckeditor
          :editor="editor"
          :placeholder="$t('questionPlaceHolder')"
          v-model="primaryTextSource"
          :config="editorConfig"
        ></ckeditor>
      </div>
    </div>
    <div class="mt-3">
      <label for="text" class="ml-3 pl-1">{{ $t("questionTag") }}:</label>
      <div style="margin-right: -12px; padding-left: 5px">
        <ckeditor
          :editor="editor"
          :placeholder="$t('questionPlaceHolder')"
          v-model="directions"
          :config="editorConfig"
        ></ckeditor>
      </div>
    </div>
    <span class="legend centered">{{ $t("essay") }}</span>
    <button class="btn secondary delete" @click="deleteComponent">
      {{ $t("delete") }}
    </button>
  </div>
</template>
<script>
import BackOfficeComponentMixin from "@/components/BackOffice/Mixins/BackOfficeComponentMixin.js";
export default {
  name: "EssayComponent",
  mixins: [BackOfficeComponentMixin],
  props: ["componentCount"],
  computed: {
    tips: {
      get() {
        return this.component.question.tips[this.locale];
      },
      set(value) {
        this.setBuilderComponentPropertyValue(value, "tips", this.locale);
      },
    },
    directions: {
      get() {
        return this.component.question.question[this.locale];
      },
      set(value) {
        this.setBuilderComponentPropertyValue(value, "question", this.locale);
      },
    },
    objectStyles() {
      return {
        order: this.component.order,
      };
    },
    primaryTextSource: {
      get() {
        return this.component.question.primaryTextSource[this.locale];
      },
      set(value) {
        this.setBuilderComponentPropertyValue(
          value,
          "primaryTextSource",
          this.locale
        );
      },
    },
    instruction: {
      get() {
        return this.component.question.instruction[this.locale];
      },
      set(value) {
        this.setBuilderComponentPropertyValue(
          value,
          "instruction",
          this.locale
        );
      },
    },
  },
  methods: {
    setBuilderComponentPropertyValue(value, propertyName, language) {
      this.$store.commit("setBuilderComponentPropertyValue", {
        component: this.component.question,
        propertyName: propertyName,
        value: value,
        language: language,
      });
    },
  },
};
</script>
<style>
/* .essay__comppnent .ck.ck-editor{
   width: min-content !important;
 } */
.count {
  color: #000;
}
</style>