var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "content-component column",
      class: [
        { "component-active": _vm.isActive },
        _vm.component.question.type
      ],
      style: _vm.objectStyles,
      on: { click: _vm.updateCurrentComponent }
    },
    [
      _c(
        "span",
        { staticClass: "count", staticStyle: { "margin-left": "-14px" } },
        [_vm._v(_vm._s(_vm.componentCount))]
      ),
      _c("div", { staticClass: "mt-5" }, [
        _c("label", { staticClass: "ml-3 pl-1" }, [
          _vm._v(_vm._s(_vm.$t("instruction")) + ":")
        ]),
        _c(
          "div",
          { staticStyle: { "margin-right": "-12px", "padding-left": "5px" } },
          [
            _c("ckeditor", {
              attrs: {
                editor: _vm.editor,
                placeholder: _vm.$t("questionPlaceHolder"),
                config: _vm.editorConfig
              },
              model: {
                value: _vm.instruction,
                callback: function($$v) {
                  _vm.instruction = $$v
                },
                expression: "instruction"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "mt-3" }, [
        _c("label", { staticClass: "ml-3 pl-1" }, [
          _vm._v(_vm._s(_vm.$t("primaryText")) + ":")
        ]),
        _c(
          "div",
          { staticStyle: { "margin-right": "-12px", "padding-left": "5px" } },
          [
            _c("ckeditor", {
              attrs: {
                editor: _vm.editor,
                placeholder: _vm.$t("questionPlaceHolder"),
                config: _vm.editorConfig
              },
              model: {
                value: _vm.primaryTextSource,
                callback: function($$v) {
                  _vm.primaryTextSource = $$v
                },
                expression: "primaryTextSource"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "mt-3" }, [
        _c("label", { staticClass: "ml-3 pl-1", attrs: { for: "question" } }, [
          _vm._v(_vm._s(_vm.$t("questionTag")) + ":")
        ]),
        _c(
          "div",
          { staticStyle: { "margin-right": "-12px", "padding-left": "5px" } },
          [
            _c("ckeditor", {
              attrs: {
                editor: _vm.editor,
                placeholder: _vm.$t("questionPlaceHolder"),
                config: _vm.editorConfig
              },
              model: {
                value: _vm.question,
                callback: function($$v) {
                  _vm.question = $$v
                },
                expression: "question"
              }
            })
          ],
          1
        )
      ]),
      _c("span", { staticClass: "legend centered" }, [
        _vm._v(_vm._s(_vm.$t("writtenLegend")))
      ]),
      _c(
        "button",
        {
          staticClass: "btn secondary delete",
          on: { click: _vm.deleteComponent }
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("delete")) + "\n  ")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }