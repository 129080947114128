<template>
  <div class="venn-diagram-set row ml-4">
    <image-upload
      :key="SetImage.id"
      :component="SetImage"
      :options="SetImageOptions"
    />
    <input
      type="text"
      v-model="SetName"
      :placeholder="$t('titlePlaceHolder')"
    />
  </div>
</template>
<script>
import BackOfficeComponentMixin from "@/components/BackOffice/Mixins/BackOfficeComponentMixin.js";
export default {
  name: "VennDiagramSet",
  mixins: [BackOfficeComponentMixin],
  props: {
    set: String,
  },
  computed: {
    SetImage() {
      return this.component.question[this.set + "Image"];
    },
    SetImageOptions() {
      return { name: "image-upload", text: "addImageText", model: "id" };
    },
    SetName: {
      get() {
        return this.component.question[this.set + "Content"][this.locale];
      },
      set(value) {
        this.setBuilderComponentPropertyValue(
          value,
          this.set + "Content",
          this.locale
        );
      },
    },
  },
  methods: {
    setBuilderComponentPropertyValue(value, propertyName, language) {
      this.$store.commit("setBuilderComponentPropertyValue", {
        component: this.component.question,
        propertyName: propertyName,
        value: value,
        language: language,
      });
    },
  },
};
</script>

